<template>
  <div>
    <v-container class="container px-0">
      <v-row
        class="title-row"
        :class="`${
          $vuetify.breakpoint.mdAndDown ? 'flex-column flex-nowrap' : ''
        }`"
      >
        <v-col class="d-flex align-center">
          <h4 class="font-weight-light">{{ $t('heading.hosting.ftp.title') }}</h4>
          <active-server-account-selector
            v-if="service && service.server_accounts.length > 1"
            class="ml-4"
            :value.sync="serverAccount"
            :items="service.server_accounts"
          />
        </v-col>

        <v-col
          v-show="items.length"
          class="page-title__filter-controls justify-end"
          :class="[`${$vuetify.breakpoint.mobile ? 'pb-3' : ''}`]"
        >
          <data-iterator-filter-controls
            :keys="keys"
            :showSearchTerm="true"
            :searchTerm="searchTerm"
            :fullWidthSearch="false"
            :fullWidthLg="false"
            :fullWidthMd="true"
            @update:sortBy="changeSortBy"
            @update:sortDesc="handleSortDescChange"
            @update:searchTerm="handleSearchTermChange"
          ></data-iterator-filter-controls>

          <v-btn
            :large="!$vuetify.breakpoint.smAndDown"
            elevation="0"
            color="primary"
            class="p-2 add-new-button page-title__add-button"
            @click="loadAddModal"
            :loading="addButtonLoading"
          >
            <v-icon :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''"
              >$plus
            </v-icon>
            {{ $vuetify.breakpoint.mobile ? "" : $t('button.ftp.add') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-3 px-0">
      <v-row>
        <v-col cols="12">
          <div :class="checkedItems.length > 0 ? 'pb-15' : ''">
            <hosting-ftp-table
              v-if="serverAccount && !serverAccount.diagnostic_mode"
              :checkedItems="checkedItems"
              :headers="headers"
              :items="items"
              :itemsLoading="itemsLoading"
              :itemsPerPage="itemsPerPage"
              :itemsTotal="itemsTotal"
              :page="page"
              :possibleItemsPerPage="[[8, 16, 24]]"
              :searchTerm="searchTerm"
              :sortBy="sortBy"
              :sortDesc="sortDesc"
              @clearFilters="searchTerm = ''"
              @update:check-all="handleSelectAll"
              @update:checked-items="handleItemCheck"
              @update:itemsPerPage="changePerPage"
              @update:page="changePage"
              @update:sortBy="changeSortBy"
              @update:sortDesc="changeSortDesc"
              @action-button-info="loadInfoModal"
              @action-button-edit="showEditModal"
              @action-button-delete="showDeleteModal"
              :itemClass="itemClass"
              :usageSupported="usageSupportedByServer"
            >
              <template v-slot:no-data>
                <TableFullScreenMessage
                  :title="$t('message.emptyTable.ftp.title')"
                  :desc="$t('message.emptyTable.ftp.description')"
                >
                  <template v-slot:image>
                    <hosting-ftp-illustration />
                  </template>
                  <template v-slot:action>
                    <v-btn
                      :large="!$vuetify.breakpoint.smAndDown"
                      :small="$vuetify.breakpoint.smAndDown"
                      elevation="0"
                      color="primary"
                      class="p-2 add-new-button"
                      @click="loadAddModal"
                    >
                      <v-icon :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''"
                        >$plus
                      </v-icon>
                      {{ $vuetify.breakpoint.mobile ? "" : $t('button.ftp.add') }}
                    </v-btn>
                  </template>
                </TableFullScreenMessage>
              </template>
              <template v-slot:updated_at="item">
                <span>{{ item.updated_at_text }}</span>
              </template>
            </hosting-ftp-table>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      transition="custom-dialog-transition"
      :persistent="modalOptions.persistent"
      v-model="modalOptions.open"
      ref="dialog"
    >
      <div
        class="card-overlay"
        v-if="!modalOptions.persistent"
        @click="modalOptions.open = !modalOptions.open"
      />
      <div class="card-overlay" v-else @click="$refs.dialog.animateClick()" />
      <basic-modal
        style="width: 560px"
        :modalOptions="modalOptions"
        @modal-close="modalClose"
        :key="modalRender"
      />
    </v-dialog>
  </div>
</template>

<script>
import Api from "../../apis/Api";

import BasicModal from "@/components/modal/BasicModal";
import DataIteratorFilterControls from "../../components/dataIterator/DataIteratorFilterControls.vue";
import HostingFtpTable from "../../components/dataIterator/tables/HostingFtpTable.vue";
import TableFullScreenMessage from "../../components/dataIterator/tables/TableFullScreenMessage.vue";
import HostingFtpIllustration from "../../components/illustrations/hosting/hosting-ftp-illustration.vue";

import ActionModalMixin from "@/mixins/ActionModalMixin";
import DataIteratorPageMixin from "../../mixins/DataIteratorPageMixin";
import CustomTablePageMixin from "../../mixins/CustomTablePageMixin";
import moment from "moment";
import ActiveServerAccountSelector from "@/components/ActiveServerAccountSelector.vue";

export default {
  components: {
    ActiveServerAccountSelector,
    BasicModal,
    DataIteratorFilterControls,
    HostingFtpTable,
    HostingFtpIllustration,
    TableFullScreenMessage,
  },
  mixins: [ActionModalMixin, DataIteratorPageMixin, CustomTablePageMixin],
  props: {
    service: Object
  },
  data() {
    return {
      isServerProvided: false,
      loading: true,
      addButtonLoading: false,
      items: [],
      usageSupportedByServer: false,
      searchTerm: "",
      headers: [
        {
          text: 'user',
          value: "user",
          sortable: true,
        },
        {
          text: 'directory',
          value: "directory",
          sortable: true,
        },
        {
          text: 'usageQuota',
          value: "usage_quota",
          sortable: true,
        },
        {
          text: 'updated',
          value: "updated_at",
          sortable: true,
        },
        {
          text: 'actions',
          value: "actions",
        },
      ],
      sortBy: "updated_at",
      sortDesc: true,
      page: 1,
      itemsPerPage: 8,
      itemsLoading: true,
      modalRender: 0,
      modalOptions: { open: false },
      highlightItem: {},
      serverAccount: null
    };
  },

  watch: {
    highlightItem: function (newValue) {
      if (!newValue) return;
      setTimeout(() => {
        this.highlightItem = {};
      }, 1000);
    },
    service: function () {
      this.serverAccount = this.service.server_accounts[0];
    },
    serverAccount: function () {
      this.reloadData();
    },
    "modalOptions.open": function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },

  methods: {
    loadAddModal() {
      this.addButtonLoading = true;
      Api.cached()
        .get(`/server-accounts/${this.serverAccount.id}/domains`)
        .then((response) => {
          this.showAddModal(response.data.data);
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.addButtonLoading = false;
        });
    },
    showAddModal(domains) {
      this.resetModal();
      this.modalOptions.title = this.$t('heading.hosting.ftp.modal.add.title')
      this.modalOptions.icon = "$plus";
      this.modalOptions.buttons.unshift({
        label: this.$t('button.ftp.add'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() && this.addFtpAccount(modal.formValues);
        },
      });
      this.modalOptions.formFields = [
        {
          label: this.$t('form.label.username'),
          type: "text-select",
          suffix: "@",
          textName: "user",
          tooltip: "tooltip.add_ftp_account.username",
          selectName: "domain",
          options: domains.map((item) => {
            return {
              label: item.domain,
              value: item.domain,
            };
          }),
          rules: [
            (v) => !!v ||
              this.$t('form.validation.required', {field: this.$t('form.label.username')}),
            (v) => (v && /.+@.+/.test(v)) ||
              this.$t('form.validation.invalid', {field: this.$t('form.label.username')}),
          ],
        },
        {
          label: this.$t('form.label.password'),
          name: "password",
          tooltip: "tooltip.add_ftp_account.password",
          type: "generatePassword",
          rules: [
            (v) => !!v ||
              this.$t('form.validation.required', {field: this.$t('form.label.password')}),
            (v) =>
              (v && v.length >= 8) ||
              this.$t('form.validation.minLength', {
                field: this.$t('form.label.password'),
                length: 8
              }),
          ],
        },
        {
          label: this.$t('form.label.directory'),
          name: "directory",
          tooltip: "tooltip.add_ftp_account.directory",
          type: "text",
          prefix: this.serverAccount.homedir,
        },
      ];
      if (this.usageSupportedByServer) {
        this.modalOptions.formFields.push(
          {
            label: this.$t('form.label.unlimitedQuota'),
            name: "unlimited_quota",
            tooltip: "tooltip.add_ftp_account.unlimited_quota",
            type: "checkbox",
            onChange: (values) => {
              for (const field of this.modalOptions.formFields) {
                if (field.name == "quota") {
                  field.hidden = values.unlimited_quota;
                }
              }
            },
          },
          {
            label: this.$t('form.label.quota'),
            name: "quota",
            tooltip: "tooltip.add_ftp_account.quota",
            type: "text",
            hidden: true,
          }
        );
      }
      this.modalOptions.item = {
        domain: domains[0].domain,
        unlimited_quota: true,
      };
      this.modalOptions.open = true;
    },
    addFtpAccount(formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      if (formData.unlimited_quota) {
        formData.quota = 0;
      }
      Api.post(
        `/server-accounts/${this.serverAccount.id}/ftp-accounts`,
        formData
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.ftp.add.success');
          this.highlightItem = { user: `${formData.user}@${formData.domain}` };
          this.reloadData();

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    loadInfoModal(item) {
      item.infoButtonLoading = true;
      Api.cached()
        .get(
          `/server-accounts/${this.serverAccount.id}/ftp-accounts/server-info`
        )
        .then((response) => {
          this.showInfoModal(item, response.data.data);
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          item.infoButtonLoading = false;
        });
    },
    showInfoModal(item, info) {
      this.resetModal();
      this.modalOptions.item = item;
      this.modalOptions.title = this.$t('heading.hosting.ftp.modal.show.title', {user: `<span style="text-wrap: nowrap">${item.user}</span>`});
      this.modalOptions.icon = "$help";

      this.modalOptions.message = "<ul>";
      for (const [key, value] of Object.entries({
        "username": item.user,
        "host": info.host,
        "port": info.port,
      })) {
        const field = this.$t('heading.hosting.ftp.modal.show.info.' + key);
        this.modalOptions.message += `<li><strong style="display:inline-block">${field}:</strong> <span style="display:inline-block">${value}</span></li>`;
      }
      this.modalOptions.message += "</ul>";

      this.modalOptions.open = true;
    },
    showEditModal(item) {
      this.resetModal();
      this.modalOptions.item = item;
      this.modalOptions.title = this.$t('heading.hosting.ftp.modal.update.title')
      this.modalOptions.icon = "$edit";
      this.modalOptions.message = `<b>${this.$t('heading.hosting.ftp.modal.update.info')}:</b> ${item.user}`;

      this.modalOptions.buttons.unshift({
        label: this.$t('button.update'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() &&
            this.updateFtpAccount(item, modal.formValues);
        },
      });

      this.modalOptions.formFields = [
        {
          label: this.$t('form.label.password'),
          tooltip: "tooltip.edit_ftp_account.password",
          name: "hiddenPassword",
          type: "hiddenPassword",
          onButtonClick: () => {
            for (const field of this.modalOptions.formFields) {
              if (field.name == "hiddenPassword") {
                field.hidden = true;
              }
              if (field.name == "password") {
                field.hidden = false;
              }
            }
          },
        },

        {
          label: this.$t('form.label.password'),
          tooltip: "tooltip.edit_ftp_account.password",
          name: "password",
          type: "generatePassword",
          hidden: true,
        },
      ];

      if (this.usageSupportedByServer) {
        this.modalOptions.formFields.push(
          {
            label: this.$t('form.label.unlimitedQuota'),
            name: "unlimited_quota",
            tooltip: "tooltip.edit_ftp_account.unlimited_quota",
            type: "checkbox",
            onChange: (values) => {
              for (const field of this.modalOptions.formFields) {
                if (field.name == "quota") {
                  field.hidden = values.unlimited_quota;
                }
              }
            },
          },
          {
            label: this.$t('form.label.quota'),
            name: "quota",
            tooltip: "tooltip.edit_ftp_account.quota",
            type: "text",
            hidden: item.diskquota == "unlimited",
          }
        );
        if (item.diskquota == "unlimited") {
          this.modalOptions.item = { unlimited_quota: true, quota: "" };
        } else {
          this.modalOptions.item = {
            unlimited_quota: false,
            quota: item.diskquota,
          };
        }
      }

      this.modalOptions.open = true;
    },
    updateFtpAccount(item, formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      if (formData.unlimited_quota) {
        formData.quota = 0;
      }
      Api.put(
        `/server-accounts/${this.serverAccount.id}/ftp-accounts/${item.user}`,
        formData
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.ftp.update.success');
          this.highlightItem = item;
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.reloadData();
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    showDeleteModal(item) {
      this.resetModal();
      this.modalOptions.item = item;
      this.modalOptions.title = this.$t('heading.hosting.ftp.modal.delete.title')
      this.modalOptions.color = "error";
      this.modalOptions.icon = "$alertwarning";

      this.modalOptions.formFields = [
        {
          message: `<b>${this.$t('message.confirmAction')}</b>`,
          label: this.$t('form.confirmDelete.ftp'),
          name: "confirm",
          type: "checkbox",
          required: true,
        },
      ];
      this.modalOptions.buttons.unshift({
        label: this.$t('button.delete'),
        color: "error",
        onclick: (modal) => {
          modal.$refs.form.validate() && this.deleteAccount(item);
        },
      });
      this.modalOptions.open = true;
    },
    deleteAccount(item) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.delete(
        `/server-accounts/${this.serverAccount.id}/ftp-accounts/${item.user}`
      )
        .then(() => {
          this.modalOptions.submittingSuccess = this.$t('notification.hosting.ftp.delete.success');
          this.reloadData();

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.modalOptions.submittingSuccess,
          });
          this.modalOptions.open = false;
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    reloadData() {
      if (this.serverAccount.diagnostic_mode) {
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: this.$t("message.hostingAccountInDiagnosticMode"),
        });
        this.itemsLoading = false;
        return;
      }
      this.itemsLoading = true;
      Api.get(`/server-accounts/${this.serverAccount.id}/ftp-accounts`)
        .then((response) => {
          this.items = response.data.data.map((item) => {
            item.usage_quota = `${this.$t('form.value.sizeMB', {value: item.diskused})} / ${
              item.diskquota == "unlimited"
                ? this.$t('form.value.unlimited')
                : this.$t('form.value.sizeMB', {value: item.diskquota})
            }`;
            item.updated_at_text = item.updated_at
              ? moment(item.updated_at).fromNow()
              : "-";
            item.infoButtonLoading = false;
            return item;
          });
          this.usageSupportedByServer = response.data.meta.usage;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.itemsLoading = false;
        });
    },
    itemClass(item) {
      if (this.highlightItem.user && item.user == this.highlightItem.user) {
        return "highlight";
      }
    },
  },
  mounted() {
    if (this.service) {
      this.serverAccount = this.service.server_accounts[0];
    }
  },
  computed: {
    itemsTotal: function () {
      return this.items.length;
    },
  },
};
</script>

<style scoped lang="scss">
.title-row {
  @media (min-width: 1401px) {
    height: 70px;
  }
}
</style>
